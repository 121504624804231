@use "sass:map";
@import "bootstrap/scss/bootstrap";

@each $breakpoint in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }

    .border#{$infix}-end {
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }

    .border#{$infix}-start {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }

    .border#{$infix}-end-0 {
      border-right: 0 !important;
    }

    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }

    .border#{$infix}-start-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}
