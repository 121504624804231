@use "colors.scss";

.badge {
  background-color: colors.$badge--default--color !important;
  color: colors.$color--black !important;
}

.badge-success {
  background-color: colors.$badge--success--color !important;
  color: colors.$color--black !important;
}

.badge-warning {
  background-color: colors.$badge--warning--color !important;
  color: colors.$color--black !important;
}

.badge-danger {
  background-color: colors.$badge--danger--color !important;
  color: colors.$color--black !important;
}
