@use "sass:map";
@use "shared/colors.scss";

// Fully switching to sass @use is only possible if the following bootstrap ticket is resolved, presumably from v6:
// https://github.com/twbs/bootstrap/issues/29853
// The corresponding build warnings have been disabled in the angular.json file.

// Overwrites bootstrap breakpoints. In line with variables.$container-max-widths and BreakpointService.
$grid-breakpoints: (
  xs: 0,
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1320px,
  xxl: 1680px,
);

@import "shared/fonts";
@import "shared/headlines";
@import "shared/spacing.scss";
@import "shared/table-background.scss";
@import "shared/buttons.scss";
@import "shared/variables.scss";
@import "shared/primeng-overwrites.scss";
@import "shared/switches.scss";
@import "shared/bootstrap-overwrites.scss";

// Overwrite colors
$dark: colors.$color--dark;
$primary: colors.$color--primary;
$secondary: colors.$color--secondary;
$danger: colors.$color--danger;

@import "shared/borders.scss";
@import "shared/badge.scss";

@import "bootstrap/scss/bootstrap";
@import "primeicons/primeicons.css";

.bold {
  font-weight: 700;
}

.container {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.row {
  max-width: calc(100vw - 32px) !important;
}

body,
html {
  width: 100vw;
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

table {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .fs--scale p {
    font-size: clamp(1rem, 1.389vw + 0.111rem, 1.5rem);
    line-height: clamp(1.2rem, 2.083vw + -0.133rem, 1.95rem);
  }
}

@media (min-width: 1600px) {
  .fs--scale p {
    font-size: 1.5rem;
    line-height: 1.95rem;
  }
}

.card {
  border-radius: 0;

  .card-header {
    background-color: #fff;
  }

  .card-footer {
    background-color: #fff;
  }
}

.content--link {
  font-family: "Open Sans", sans-serif;
  width: max-content;
  color: colors.$color--black;
  text-decoration: none;
  border-bottom: 1px solid colors.$color--primary;
  padding-bottom: 2px;

  &:hover,
  &:active,
  &:focus {
    border-bottom: none;
    padding-bottom: 3px;
  }
}

// TODO move to component
.p-datatable {
  .p-datatable-thead > tr > th {
    font-size: 1rem;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
  }

  .p-datatable-tbody > tr {
    font-size: 14px;

    &:hover {
      background-color: #e9ecef;
    }
  }
}

.dropdown-menu {
  border-radius: 0;
}

.disabled {
  opacity: 0.8;
}

// Legacy for buttons
.mr-5 {
  margin-right: 0.5rem;
}

.trace--id {
  font-size: 1rem;
  color: colors.$color--grey;
}

th,
td {
  vertical-align: top !important;
}

.middle {
  vertical-align: middle !important;
}

* > :not(.p-focus > *):focus-visible {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem colors.$color--border !important;
  border-color: colors.$color--grey !important;
}

.modal-content {
  border: unset !important;
}

.z-index-max {
  z-index: 9999 !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
  -webkit-backdrop-filter: blur(2.5px) !important;
  backdrop-filter: blur(2.5px) !important;
  opacity: 1 !important;
}
