@use "colors.scss";

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.form-check-input:checked {
  background-color: colors.$color--black !important;
  border-color: colors.$color--black !important;
}

.form-check-input:focus {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem colors.$color--border !important;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23DADDE0'/%3e%3c/svg%3e") !important;
}
