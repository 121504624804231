@use "sass:map";
@import "bootstrap/scss/bootstrap";

$spaceamounts: (0, 2, 4, 8, 16, 24, 32, 64, 128);
$sides: (
  "": "all",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);

@each $breakpoint in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $space in $spaceamounts {
      @each $prefix, $value in $sides {
        $property: if($prefix == "", "", -#{$value});

        .m#{$prefix}#{$infix}--#{$space} {
          margin#{$property}: #{$space}px !important;
        }

        .p#{$prefix}#{$infix}--#{$space} {
          padding#{$property}: #{$space}px !important;
        }
      }
    }
  }
}
