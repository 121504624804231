@use "colors.scss";

// Make the Summary of PrimeNg Messages bolder
[data-pc-section="summary"] {
  font-weight: bolder !important;
}

.p-message-icon {
  display: flex;
}
